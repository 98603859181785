const { config } = require('./../commerce/config')
const { defaultLocale } = config

const processLocale = (locale: string | undefined) => {
  let processLocale = locale || defaultLocale

  return processLocale
}

export type PaymentMethods =
  | 'VISA'
  | 'MASTERCARD'
  | 'APPLEPAY'
  | 'GOOGLEPAY'
  | 'SHOPPAY'
  | 'BANKTRANSFER'
  | 'AMEX'
  | 'MAESTRO'
  | 'BANCONTACT'
  | 'IDEAL'
  | 'SOFORT'
  | 'KLARNA'

interface LOCALE_DATA {
  url?: string
  name: string
  code: string
  value: string
  optionLabel: string
  selectionLabel: string
  customMessage?: string
  currency: { name: string; value: string }[]
  img: {
    filename: string
    alt: string
  }
}

type REGION_SWITCHER_ITEM = {
  value: string
  region: string
  language: string
  currency: string
  label: string
  labelKey: string
  customMessage?: string
}

export const REGION_OPTIONS: { name: string; value: string }[] = [
  { name: 'United States / Canada', value: 'us' },
  { name: 'Australia', value: 'au' },
  { name: 'Europe', value: 'eu (int)' },
  { name: 'United Kingdom', value: 'gb' },
  { name: 'International', value: 'intl' },
]
export const LANGUAGE_OPTIONS: { name: string; value: string }[] = [
  { name: 'English', value: 'en' },
  { name: 'Deutsch', value: 'de' },
  { name: 'Español', value: 'es' },
]
export const CURRENCY_OPTIONS: { name: string; value: string }[] = [
  { name: '$ USD', value: 'usd' },
  { name: '$ AUD', value: 'aud' },
  { name: '€ EUR', value: 'eur' },
  { name: '£ GBP', value: 'gbp' },
]

export const LOCALE_SWITCHER: REGION_SWITCHER_ITEM[] = [
  {
    value: 'en-us',
    region: 'us',
    language: 'en',
    currency: 'usd',
    label: 'US / CA store',
    labelKey: 'us-ca',
    // customMessage:
    // '*Now shipping to USA and Canada from the European store. Prices in € EUR and freight calculated at checkout.',
  },
  {
    value: 'es-us',
    region: 'us',
    language: 'es',
    currency: 'usd',
    label: 'US / CA store',
    labelKey: 'us-ca',
    // customMessage:
    // '*Now shipping to USA and Canada from the European store. Prices in € EUR and freight calculated at checkout.',
  },
  {
    value: 'en-au',
    region: 'au',
    language: 'en',
    currency: 'aud',
    label: 'Australian store',
    labelKey: 'australian',
  },
  {
    value: 'en-eu',
    region: 'eu (int)',
    language: 'en',
    currency: 'eur',
    label: 'European store',
    labelKey: 'european',
  },
  {
    value: 'de-eu',
    region: 'eu (int)',
    language: 'de',
    currency: 'eur',
    label: 'European store',
    labelKey: 'european',
  },
  {
    value: 'es-eu',
    region: 'eu (int)',
    language: 'es',
    currency: 'eur',
    label: 'European store',
    labelKey: 'european',
  },
  {
    value: 'en-eu',
    region: 'intl',
    language: 'en',
    currency: 'eur',
    label: 'International store',
    labelKey: 'international',
  },
  {
    value: 'de-eu',
    region: 'intl',
    language: 'de',
    currency: 'eur',
    label: 'International store',
    labelKey: 'international',
  },
  {
    value: 'es-eu',
    region: 'intl',
    language: 'es',
    currency: 'eur',
    label: 'International store',
    labelKey: 'international',
  },
  {
    value: 'en-gb',
    region: 'gb',
    language: 'en',
    currency: 'gbp',
    label: 'UK store',
    labelKey: 'uk',
  },
]

export const getConfigByLocale = (
  locale: string | undefined,
  configName: string
) => {
  const { context } = config
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  return currentContext?.[configName]
}

export default processLocale
